import request from '@/utils/request'

export function fetchCategoryTree() {
    return request({
        url: '/admin/category/tree',
        method: 'get'
    })
}

export function fetchProductList(query) {
    return request({
        url: '/admin/product/list',
        method: 'get',
        params: query
    })
}

export function fetchProductNoImgList(query) {
    return request({
        url: '/admin/product/noimg/list',
        method: 'get',
        params: query
    })
}

export function fetchList(query) {
    return request({
        url: '/admin/product/listbypinfoid',
        method: 'get',
        params: query
    })
}

export function fetchListByKeyword(query) {
    return request({
        url: '/admin/product/listbykeyword',
        method: 'get',
        params: query
    })
}
